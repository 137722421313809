const axios = require("axios");
const { Alert } = require("reactstrap");
const { refreshAccessToken } = require("./RefershAccessToken");

const remote = {
    address: 'https://edev.englishhub.app/api/v1/',
    // address:'https://live.englishhub.app/api/v1/'
  
};

function getRequests(api) {
    let token = localStorage.getItem("token");
    const apiUrl = remote.address + api;
    let promise = new Promise((resolve, reject) => {
        axios
            .get(apiUrl, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                var data = res.data;
                resolve(data);
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.code === 'token_not_valid') {
                    refreshAccessToken()
                }
                var errorMessage = error.response.data.Message || error.response.data || error.response;
                reject(errorMessage);
            });
    });
    return promise;
}

function fullLinkGetRequests(api) {
    let token = localStorage.getItem("token");
    const apiUrl = api;
    let promise = new Promise((resolve, reject) => {
        axios
            .get(apiUrl, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                var data = res.data;
                resolve(data);
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.code === 'token_not_valid') {
                    refreshAccessToken()
                }
                var errorMessage = error.response.data.Message || error.response.data || error.response;
                reject(errorMessage);
            });
    });
    return promise;
}


function postRequests(api, data) {
    let token = localStorage.getItem("token");
    const apiUrl = remote.address + api;
    let promise = new Promise((resolve, reject) => {
        if (token) {
            axios
                .post(apiUrl, data, { headers: { Authorization: `Bearer ${token}` } })
                .then((res) => {
                    var data = res.data;
                    resolve(data);
                })
                .catch((error) => {
                    if (error) {
                        var errorMessage = error?.response?.data?.Message || error?.response?.data || error?.response || 'Error Message Not Handled';
                        reject(errorMessage);
                    } else {
                        reject("Error Message Not Handled");
                    }

                });
        }
        else {
            axios
                .post(apiUrl, data)
                .then((res) => {
                    var data = res.data;
                    resolve(data);
                })
                .catch((error) => {
                    if (error) {
                        var errorMessage = error?.response?.data?.Message || error?.response?.data || error?.response || 'Error Message Not Handled';
                        reject(errorMessage);
                    } else {
                        reject("Error Message Not Handled");
                    }

                });
        }
    });
    return promise;
}

function patchRequests(api, data) {
    let token = localStorage.getItem("token");
    const apiUrl = remote.address + api;
    let promise = new Promise((resolve, reject) => {
        axios
            .patch(apiUrl, data, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                var data = res.data;
                resolve(data);
            })
            .catch((error) => {
                var errorMessage = error.response.data.Message;
                reject(errorMessage);
            });
    });
    return promise;
}

function deleteRequests(api) {
    let token = localStorage.getItem("token");
    const apiUrl = remote.address + api;
    let promise = new Promise((resolve, reject) => {
        axios
            .delete(apiUrl, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                var data = res.data;
                resolve(data);
            })
            .catch((error) => {
                var errorMessage = error.response.data.Message;
                reject(errorMessage);
            });
    });
    return promise;
}

function putRequests(api, data) {
    let token = localStorage.getItem("token");
    const apiUrl = remote.address + api;
    let promise = new Promise((resolve, reject) => {
        axios
            .put(apiUrl, data, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                var data = res.data;
                resolve(data);
            })
            .catch((error) => {
                var errorMessage = error.response.data.Message;
                reject(errorMessage);
            });
    });
    return promise;
}

var RemoteServices = {

    postHierarchy: function (id, data) {
        const url = `hirerchy/user-hirerchy/${id}`
        return postRequests(url, data)
    },

    recentlyJoinedUser: function (id, data) {
        const url = `hirerchy/recent-join-users/${id}`
        return postRequests(url, data)
    },

    getLatestReferral: function (fromDate, toDate) {
        const url = `user/referrals/`
        return getRequests(url)
    },

    setLinkWalletDetails: function (data) {
        const url = "user/linkpayments/list/";
        return postRequests(url, data);
    },
    getWalletDetails: function (data) {
        const url = "students/wallet/";
        return getRequests(url);
    },

    getNewWalletDetails: function (id) {
        const url = `hirerchy/user-wallet/${id}`;
        return postRequests(url);
    },
    setWalletDetails: function (data) {
        const url = "students/wallet/";
        return postRequests(url, data);
    },
    withdrawRequest: function (data) {
        const url = "students/payment-requests/";
        console.log('aaa', data)
        return postRequests(url, data);
    },
    addUser: (data) => {
        const url = 'user/';
        return postRequests(url, data)
    },
    sendLogin: function (data) {
        const url = "user/get-token/";
        return postRequests(url, data);
    },
    getLatestLessonDashboard: function (data) {
        const url = "lessons/latest/?location=Dashboard";
        return getRequests(url);
    },
    getArticles: function (data) {
        const url = "articles/categorywise/";
        return getRequests(url);
    },
    checkPromoCode: function (data) {
        const url = "user/promocode/list/promocode_verify/";
        return postRequests(url, data);
    },
    getArticlesById: function (id) {
        const url = `articles/${id}/`;
        return getRequests(url);
    },
    getAssignmentDetail: function (id) {
        const url = `students/assigment/${id}/`;
        return getRequests(url);
    },
    getTeacherSupport: function (data) {
        const url = "user/meet/list/";
        return getRequests(url);
    },
    getLatestLesson: function (data) {
        const url = "lessons/latest/?location=Lesson";
        return getRequests(url);
    },

    getNotifications: function (id) {
        const url = `notifications/me/`;
        return getRequests(url);
    },
    getLessonsHistory: function (data) {
        const url = `lessons/previous-v2/?recent_history=${data}`;
        return getRequests(url);
    },
    getLessonById: function (id) {
        const url = `lessons/${id}`;
        return getRequests(url);
    },
    getLessonsHistoryByKeyword: function (data) {
        const url = `lessons/previous-v2?keyword=${data}`;
        return getRequests(url);
    },
    getRecentLessonsHistory: function (data) {
        const url = "lessons/recentprevious-v2/";
        return getRequests(url);
    },
    getLessonCourse: function (data) {
        const url = "lessons/filters/";
        return getRequests(url);
    },
    searchWithCourseId: function (id) {
        const url = `lessons/previous-v2/?course_id=${id}`;
        return getRequests(url);
    },
    sendAssignment: function (data) {
        const url = "students/assigment/";
        return postRequests(url, data);
    },


    verifyEmail: function (data) {
        const url = 'user/verify-email/';
        return postRequests(url, data)

    },

    verifyOtp: function (data) {
        const url = 'user/verify-email-otp/';
        return postRequests(url, data)

    },

    changeOtpPassword: function (data) {
        const url = 'user/change-password-email-otp/';
        return postRequests(url, data)

    },
    getCourses: function (data) {
        const url = "courses/";
        return getRequests(url);
    },
    getWalletInformation: function (data) {
        const url = "students/previous_payment_request/";
        return getRequests(url);
    },
    deleteCourse: function (id) {
        const url = "courses/" + id + "/";
        return deleteRequests(url);
    },


    createCourse: function (data) {
        const url = "courses/";
        return postRequests(url, data);
    },
    updateCourse: function (id, data) {
        const url = "courses/" + id + "/";
        return patchRequests(url, data);
    },
    getLessonList: function (id) {
        const url = id ? "lessons/?course=" + id : "lessons/";
        return getRequests(url);
    },
    getLesson: function (id) {
        const url = `lessons/${id}`;
        return getRequests(url);
    },
    getLessonDetail: function (id) {
        const url = `lessons/lesson-detail/${id}`;
        return getRequests(url);
    },
    updateLesson: function (lessonId, data) {
        const url = "lessons/" + lessonId + "/";
        return patchRequests(url, data);
    },
    deleteLesson: function (lessonId) {
        const url = "lessons/" + lessonId + "/";
        return deleteRequests(url);
    },
    createLesson: function (courseId, data) {
        const url = "lessons/";
        return postRequests(url, data);
    },
    getAssignmentList: function (courseId) {
        const url = "students/assigment/";
        return getRequests(url);
    },
    getPendingAssignments: function (courseId) {
        const url = courseId
            ? "students/assigment/?status=Pending&student_course_course=" + courseId
            : "students/assigment/?status=Pending";
        return getRequests(url);
    },
    getApprovedAssignments: function (courseId) {
        const url = courseId
            ? "students/assigment/?status=Approved&student_course_course=" + courseId
            : "students/assigment/?status=Approved";
        return getRequests(url);
    },
    getDeniedAssignments: function (courseId) {
        const url = courseId
            ? "students/assigment/?status=Denied&student_course_course=" + courseId
            : "students/assigment/?status=Denied";
        return getRequests(url);
    },
    updateAssignment: function (assignmentId, data) {
        const url = "students/assigment/" + assignmentId + "/";
        return patchRequests(url, data);
    },
    getStudents: function (data) {
        const url = "students/";
        return getRequests(url);
    },
    getStudentsDetails: function (id) {
        const url = "students/" + id + "/";
        return getRequests(url);
    },
    createStudent: function (data) {
        const url = "user/";
        return postRequests(url, data);
    },
    getRequestedStudents: function (data) {
        const url = "students/?course_requested=true";
        return getRequests(url);
    },
    getPaidStudents: function (data) {
        const url = "students/?payment_status=Paid";
        return getRequests(url);
    },
    getHasReferralsStudents: function (data) {
        const url = "students/?user__referrer__isnull=false";
        return getRequests(url);
    },
    getYoutubeUsers: function (data) {
        const url = "students/?group=youtuber";
        return getRequests(url);
    },
    getTestUsers: function (data) {
        const url = "students/?group=test";
        return getRequests(url);
    },
    getDueUsers: function (data) {
        const url = "students/?payment_status=Due";
        return getRequests(url);
    },
    getNonYoutubeUsers: function (data) {
        const url = "students/?group=student";
        return getRequests(url);
    },
    updateStudent: function (id, data) {
        const url = "students/" + id + "/";
        return patchRequests(url, data);
    },
    updateUserAmount: function (id, data) {
        const url = "user/" + id + "/amount_update/";
        return patchRequests(url, data);
    },
    getStaffs: function (data) {
        const url = "user/?is_staff=true";
        return getRequests(url);
    },
    updateStaff: function (id, data) {
        const url = "user/" + id + "/";
        return patchRequests(url, data);
    },
    createUser: function (data) {
        const url = "user/";
        return postRequests(url, data);
    },
    deleteStaff: function (id) {
        const url = "user/" + id + "/";
        return deleteRequests(url);
    },
    createArticle: function (data) {
        const url = "articles/";
        return postRequests(url, data);
    },
    deleteArticle: function (id) {
        const url = "articles/" + id + "/";
        return deleteRequests(url);
    },
    updatePassword: function (id, data) {
        const url = "user/" + id + "/password_change/";
        return putRequests(url, data);
    },
    getDefaultLinks: function (data) {
        const url = "default-links/";
        return getRequests(url);
    },
    deleteDefaultLink: function (id) {
        const url = "default-links/" + id + "/";
        return deleteRequests(url);
    },
    updateDefaultLink: function (id, data) {
        const url = "default-links/" + id + "/";
        return patchRequests(url, data);
    },
    getDashboardData: function () {
        const url = "user/stats/";
        return getRequests(url);
    },
    getMonthlyDashboardData: function () {
        const url = "user/monthly-stats/";
        return getRequests(url);
    },
    getWeeklyDashboardData: function () {
        const url = "user/weekly-stats/";
        return getRequests(url);
    },
    getAssignmentsDashboardData: function () {
        const url = "user/assignment-stats/";
        return getRequests(url);
    },
    getCustom: function (api) {
        console.log('asdfasdf',api)
        return getRequests(api);
    },
    getLinkNext: function (api) {
        return fullLinkGetRequests(api);
    },
    getStudentsSearch: function (search) {
        const url = "students/?search=" + search;
        return getRequests(url);
    },
    createSmsCampaign: function (data, str) {
        const url = "campaign/send-sms/" + str;
        return postRequests(url, data);
    },
    createEmailCampaign: function (data, str) {
        const url = "campaign/send-email/" + str;
        return postRequests(url, data);
    },

    saveKhaltiPayment: function (data) {
        const url = "students/payment_request/list/"
        return postRequests(url, data);
    },
    createNotificationCampaign: function (data, str) {
        const url = "campaign/send-notification/" + str;
        return postRequests(url, data);
    },
    getLogData: function (search) {
        const url = "campaign/logs/";
        return getRequests(url);
    },
    getOnlySms: function (search) {
        const url = "campaign/logs/?type=sms";
        return getRequests(url);
    },
    getOnlyEmail: function (search) {
        const url = "campaign/logs/?type=email";
        return getRequests(url);
    },
    getOnlyNotifications: function (search) {
        const url = "campaign/logs/?type=push";
        return getRequests(url);
    },
    getOnlySuccess: function (search) {
        const url = "campaign/logs/?status=success";
        return getRequests(url);
    },
    getOnlyFailed: function (search) {
        const url = "campaign/logs/?status=failed";
        return getRequests(url);
    },
    getAssignmentListHistory: function (id) {
        const url = "students/assigment/?student_course__student=" + id;
        return getRequests(url);
    },
    updateCurrentLesson: function (data) {
        const url = "students/course/set-lesson/";
        return postRequests(url, data);
    },
    getPaymentDetail: function (data) {
        return getRequests("user/get-payment-detail/");
    },
    savePayment: function (data) {
        return postRequests("user/save-payment/", data);
    },
   
    reviewsList: function (lesson, offset, tag) {
       
        return getRequests(
            `lessons/reviews/list/?lesson=${lesson}&limit=10&offset=${offset}&publish=true&&ordering=-${tag}`
        );
    },
    review: function (data) {
        return postRequests("lessons/reviews/list/", data);
    },
    replyReview: function (data) {
        return postRequests("lessons/reviews/list/reply/", data);
    },
    likeReview: function (data) {
        return postRequests("lessons/reviews/list/like/", data);
    },
    likeFreeReview: function (data) {
        return postRequests("lessons/freereviews/list/like/", data);
    },
    deleteReview: function (id) {
        return deleteRequests(`lessons/reviews/list/${id}/`);
    },
    deleteFreeReview: function (id) {
        return deleteRequests(`lessons/freereviews/list/${id}/`);
    },
    deleteReply: function (id) {
        return deleteRequests(`lessons/reviews/list/${id}/reply/`);
    },
    deleteFreeReply: function (id) {
        return deleteRequests(`lessons/freereviews/list/${id}/reply/`);
    },
    editReview: function (comment) {
        return putRequests(`lessons/reviews/list/${comment.id}/`, comment);
    },
    editFreeReview: function (comment) {
        return putRequests(`lessons/freereviews/list/${comment.id}/`, comment);
    },
    editReply: function (comment) {
        return putRequests(`lessons/reviews/list/${comment.id}/reply/`, comment);
    },
    editFreeReply: function (comment) {
        return putRequests(`lessons/freereviews/list/${comment.id}/reply/`, comment);
    },
    // pradip
    freeReviewsList: function (lesson, offset) {
        return getRequests(
            `lessons/freereviews/list/?lesson=${lesson}&limit=10&publish=true&offset=${offset}&ordering=id`
        );
    },
    freeFilterReviewsList: function (lesson, offset, tag) {
        return getRequests(
            `lessons/freereviews/list/?lesson=${lesson}&limit=10&publish=true&&offset=${offset}&ordering=-${tag}`
        );
    },
    freeReview: function (data) {
        return postRequests("lessons/freereviews/list/", data);
    },
    freeReplyReview: function (data) {
        return postRequests("lessons/freereviews/list/reply/", data);
    },
    freeLikeReview: function (data) {
        return postRequests("lessons/freereviews/list/like/", data);
    },
    createExchangeToken: function (data) {
        const url = 'exchange_token/facebook/'
        return postRequests(url, data)
    },
    createGoogleExchangeToken: function (data) {
        const url = 'exchange_token/google-oauth2/'
        return postRequests(url, data)
    },
    getQuizList: function (data) {
        const url = "students/quiz/";
        return postRequests(url, data);
    },
    getFreeQuizList: function (data) {
        const url = "students/freequiz/";
        return postRequests(url, data);
    },
    submitFreeQuiz: function (data) {
        const url = "students/freequizsubmit/";
        return postRequests(url, data);
    },
    submitQuiz: function (data) {
        const url = "students/quizsubmit/";
        return postRequests(url, data);
    },
    changePassword: function (id, data) {
        const url = `user/${id}/password_change/`;
        return putRequests(url, data);
    },
    changeProfilePicture: function (id, data) {
        const url = `user/${id}/update-picture/`;
        return patchRequests(url, data);
    },
    getFreeLessons: function (id) {
        const url = `lessons/freelesson/list/${id}/`;
        return getRequests(url);
    },
    getFreeLessonsList: function (data, search) {
        let url = ''
        if (!data || data === 'all') {
            url = "lessons/freelesson/list/";
        } else {
            url = `lessons/freelesson/list/?search=${search}&limit=100&offset=0&ordering=-${data}`
        }
        return getRequests(url);
    },

    getFreeSearchLessonsList: function (data) {
        let url = ''
        url = `lessons/freelesson/list/?search=${data}&limit=100&offset=0`
        return getRequests(url);
    },
    getUserDetails: function (id) {
        const url = `students/?user_id=${id}`;
        return getRequests(url)
    },
    getUserDetailsById: function (id) {
        const url = `user/${id}`;
        return getRequests(url)
    },

    getUserReferrals: function () {
        const url = `user/referrals/`;
        return getRequests(url)

    },
    likeFreeLesson: function (data) {
        return postRequests("lessons/freelesson/list/like/", data);
    },
    getReviewList: function (tag) {
        let url = ''
        if (tag && tag !== 'relevant') {
            url = `app/review/?publish=true&ordering=-${tag}`
        } else {
            url = 'app/review/?publish=true&relevant=true'
        }
        return getRequests(url)
    },
    getNextReviewList: function (nextUrl) {
        const url = `${nextUrl}`;
        return getRequests(url)
    },
    submitReview: function (data) {
        const url = "app/review/";
        return postRequests(url, data);
    },

    addReviewLike: function (data) {
        const url = "app/review/like/";
        return postRequests(url, data);
    },
    addReviewReplyLike: function (data) {
        const url = "app/review/like/";
        console.log("addReviewReplyLike", data);

        return postRequests(url, data);
    },
    appReviewReply: function (data) {
        const url = 'app/review/reply/';
        return postRequests(url, data)
    },

    //community
    addCommunityPost: function (data) {
        const url = `community/`
        return postRequests(url, data)
    },

    getCommunity: function (url) {
        return getRequests(url);
    },
    deleteCommunity: function (data) {
        const url = `community/${data.id}/`;
        return deleteRequests(url);
    },
    updateCommunity: function (data) {
        const url = `community/${data.id}/`;
        return patchRequests(url, data);
    },
    likeCommunity: function (data) {
        const url = `community/like/`;
        return postRequests(url, data);
    },
    likeReplyCommunity: function (data) {
        const url = `community/comment/list/like/`;
        return postRequests(url, data);
    },
    deleteLikeCommunity: function (data) {
        const url = `community/post-like/${data.post}/`;
        return deleteRequests(url, data);
    },
    addCommentCommunity: function (data) {
        const url = `community/comment/list/`;
        return postRequests(url, data);
    },
    addCommentReplyCommunity: function (data) {
        const url = `community/comment/list/reply/`;
        return postRequests(url, data);
    },

    deleteCommunityCommentList: function (data) {
        const url = `community/comment/list/${data}`
        return deleteRequests(url)
    },

    deleteCommunityCommentReplyList: function (data) {
        const url = `community/comment/list/deletereply/`
        return postRequests(url, data)
    },

    updateCommentCommunity: function (data) {
        const url = `community/comment/list/update/`
        return postRequests(url, data)
    },
    updateCommentReplyCommunity: function (data) {
        const url = `community/comment/list/updatereply/`
        return postRequests(url, data)
    },

    getCommentListCommunity: function (url) {
        return getRequests(url);
    },
    likeCommentCommunity: function (data) {
        const url = `community/comment-like/`;
        return postRequests(url, data);
    },
    deleteLikeCommentCommunity: function (data) {
        const url = `community/comment-like/${data.comment}/`;
        return deleteRequests(url, data);
    },
    editCommunityComment: function (data) {
        const url = `community/comment/${data.post}/`;
        return patchRequests(url, { comment: data.comment });
    },
    deleteCommunityComment: function (data) {
        const url = `community/comment/${data.id}/`;
        return deleteRequests(url, data);
    },
    addCommentCommunityReply: function (data) {
        const url = `community/reply/`;
        return postRequests(url, data);
    },
    editCommunityCommentReply: function (data) {
        const url = `community/reply/${data.comment}/`;
        return patchRequests(url, { reply: data.reply });
    },
    deleteCommunityCommentReply: function (data) {
        const url = `community/reply/${data.id}/`;
        return deleteRequests(url, data);
    },
    likeCommunityCommentReply: function (data) {
        const url = `community/reply-like/`;
        return postRequests(url, data);
    },
    deleteLikeCommunityCommentReply: function (data) {
        const url = `community/reply-like/${data.id}/`;
        return deleteRequests(url, data);
    },
    showLikeList: function (data) {
        const url = `${data}`;
        return getRequests(url);
    },
    getPostById: function (data) {
        const url = `community/post/${data}/`;
        return getRequests(url);
    },
    showShareList: function (data) {
        const url = data;
        return getRequests(url);
    },
    showSuggestionList: function (data) {
        const url = "community/suggestions/";
        return postRequests(url, data);
    },
    getPostByUser: function (data) {
        const url = data;
        return getRequests(url);
    },
    followUser: function (data) {
        const url = `community/following/`;
        return postRequests(url, data);
    },
    unFollowUser: function (data) {
        const url = `community/following/${data}/`;
        return deleteRequests(url);
    },
    //referred User
    referredUser: function () {
        const url = 'community/toprefereduser/'
        return getRequests(url)
    },
    //follow User
    followUsers: function (data) {
        const url = 'community/following/list/'
        return postRequests(url, data)
    },
    //following List

    getFollowingList: function (key, data) {
        const url = `community/following/list/?${key}=${data}`
        return getRequests(url)
    },

    saveFcmToken: function (data) {
        const url = 'devices/'
        return postRequests(url, data)
    },

    postPaymentById: function (data) {
        const url = "user/get-payment-details/";
        return postRequests(url, data);
    },
    deleteWalletDetail: function (id) {
        const url = "students/wallet/" + id + "/";
        return deleteRequests(url);
    },
    getChatMessage:function(memberId){
        const url=`chat/get-messages/?member_id=${memberId}`
        return getRequests(url)
    },
    postChatMessage: function (data) {
        const url = `chat/post-user-message/`
        return postRequests(url, data)
    },
    getChatConfig: function () {
        const url = 'chat/chat-config/'
        return getRequests(url)
    },
};

module.exports = RemoteServices;
