/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import About from "views/examples/About.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
// import Settings from "views/examples/Settings.js";
// import Tables from "views/examples/Tables.js";
// import Assignments from "views/examples/Assignments.js";
import Lessons from "views/examples/Lessons.js";
import FreeLessons from 'views/examples/FreeLessons'
import Notifications from "views/examples/Notifications.js";
import LessonHistory from "views/examples/LessonHistory.js";
import PrivacyPolicy from "./views/examples/PrivacyPolicy";
import Community from './views/examples/Community';
import Review from './views/examples/Review';
import OtherProfile from "./views/examples/OtherProfile";
import Esewa from "./components/Payment/esewa";
import Reward from "./views/examples/Reward";
import { ReferAndEarn } from "./views/examples/ReferAndEarn";
import Khalti from "./components/Payment/khalti";
import ForgotPassowrd from "views/examples/ForgotPassword";
import ChangePassword from "views/examples/ChangePassword";
import TeacherSupport from "./views/examples/TeacherSupport";
import MessageUs from "views/examples/MessageUs";
// import Articles from "views/examples/Articles.js";
// import main from "views/examples/leadstosales/main.js"

var routes = [
  {
    path: '/privacyPolicy',
    name: 'Privacy Policy',
    component: PrivacyPolicy,
    layout: '/public',
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    // img:dashboard,
    component: Index,
    layout: "/admin",
    user: 2
  },
  // {
  //   path: "/Assignments",
  //   name: "Lessons",
  //   icon: "ni ni-planet text-blue",
  //   component: Assignments,
  //   layout: "/admin",
  //   user: 2
  // },
  {
    path: "/FreeLessons",
    name: "FreeLessons",
    icon: "ni ni-money-coins text-primary",
    component: FreeLessons,
    layout: "/admin",
    user: 2
  },
  {
    path: "/Lessons",
    name: "Lessons",
    icon: "ni ni-book-bookmark text-primary",
    component: Lessons,
    layout: "/admin",
    user: 2
  },
  {
    path: "/Reward",
    name: "Rewards",
    icon: "ni ni-book-bookmark text-primary",
    component: Reward,
    layout: "/admin",
    user: 2
  },
  {
    path: "/Notifications",
    name: "Notifications",
    icon: "ni ni-bell-55 text-primary",
    component: Notifications,
    layout: "/admin",
    user: 2
  },
  {
    path: "/history",
    name: "History",
    icon: "ni ni-calendar-grid-58 text-primary",
    component: LessonHistory,
    layout: "/admin",
    user: 2
  },

  {
    path: "/esewa",
    name: "Esewa",
    icon: "ni ni-calendar-grid-58 text-primary",
    component: Esewa,
    layout: "/admin",
    user: 2
  },
  {
    path: "/khalti",
    name: "Khalti",
    icon: "ni ni-calendar-grid-58 text-primary",
    component: Khalti,
    layout: "/admin",
    user: 2
  },
  {
    path: "/community",
    name: "Community",
    icon: "ni ni-paper-diploma text-primary",
    component: Community,
    layout: "/admin",
    user: 2
  },
  {
    path: "/review",
    name: "Review",
    icon: "ni ni-single-copy-04 text-primary",
    component: Review,
    layout: "/admin",
    user: 2
  },

  {
    path: "/personalProfile",
    name: "Profile",
    icon: "ni ni-circle-08 text-primary",
    component: Profile,
    layout: "/admin",
    user: 2
  },
  {
    path: "/referAndEarn",
    name: "ReferAndEarn",
    icon: "fa fa-users text-primary",
    component: ReferAndEarn,
    layout: "/admin",
    user: 2
  },
  {
    path: "/MessageUs",
    name: "MessageUs",
    icon: "ni ni-chat-round text-primary",
    component: MessageUs,
    layout: "/admin",
    user: 2
  },
  {
    path: "/TeacherSupport",
    name: "TeacherSupport",
    icon: "ni ni-headphones text-primary",
    component: TeacherSupport,
    layout: "/admin",
    user: 2
  },

  {
    path: "/otherProfile/:id",
    component: OtherProfile,
    layout: "/profile",
  },
  {
    path: "/leadstosales",
    name: "About",
    icon: "ni ni-bullet-list-67 text-primary",
    component: About,
    layout: "/admin",
    user: 2
  },

  {
    path: "/login",
    name: "Logout",
    icon: "ni ni-single-02 text-primary",
    component: Login,
    layout: "/auth",
    user: 4
  },
  {
    path: "/forgotPassword",
    name: "Forgot Password",
    icon: "ni ni-single-02 text-primary",
    component: ForgotPassowrd,
    layout: "/auth",
    user: 4
  },
  {
    path: "/changePassword",
    name: "Change Password",
    icon: "ni ni-single-02 text-primary",
    component: ChangePassword,
    layout: "/auth",
    user: 4
  },

  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  }
];
export default routes;
