import React from 'react';
import {Button, Modal} from "reactstrap";
import MaterialTable from 'material-table';
import avatar from '../../../assets/img/theme/avatarnew.png'
import {useHistory} from "react-router-dom";
import moment from "moment";

const ReferralModal = ({
                           showModal,
                           toggleModal,
                           heading,
                           tableData,
                           platform,
                           toggleShareModal
                       }) => {

    const history = useHistory();

    const showUserProfile = (id) => {
        history.push(`/profile/otherProfile/${id}`);
    }
    return (
        <Modal
            keyboard={false}
            backdrop={'static'}
            className="modal-dialog-centered modal-lg"
            isOpen={showModal}
            toggle={() => toggleModal()}
        >
            <div className="modal-header">
                <h2 style={{justifyContent: 'center'}} className="modal-title" id="modal-title-default">
                    {heading}
                </h2>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => toggleModal()}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className={'modal-body m-0 p-0 '}>
                <div className={'m-4'}>
                    <MaterialTable
                        options={{
                            search: false,
                            showTitle: false,
                            toolbar: false,
                            actionsColumnIndex: -1,
                            padding: "dense"
                        }}


                        columns={[
                            {
                                title: 'Photo',
                                field: 'imageUrl',
                                width: '8%',
                                render: rowData => <img src={rowData.imageUrl || avatar}
                                                        style={{width: 40,height:40, borderRadius: '50%'}}/>
                            },
                            {
                                title: 'Name', field: 'full_name', width: "20%", render: rowData => <>
                                    <span onClick={() => showUserProfile(rowData.id)}
                                          style={{cursor: 'pointer'}}>{rowData.full_name}</span>
                                </>
                            },
                            {
                                title: 'Status', field: 'full_name', width: "20%", render: rowData => <>
                                    <div
                                        className={rowData?.payment_status === 'Paid' ? 'badge-success' : 'badge-danger'}
                                        style={{
                                            display: "flex",
                                            justifyContent: 'center',
                                            width: '60px', padding: '2px', borderRadius: '10px', cursor: 'pointer'
                                        }}>
                                        <span style={{fontSize: '12px'}}>{rowData?.payment_status}</span>
                                    </div>
                                </>
                            },
                            {
                                title: 'Joined',
                                field: 'payment_status',
                                render: rowData => <>
                                                <span >
                                                    {rowData?.course_join_date?.toUpperCase() === 'NONE' ?
                                                        moment(rowData?.created_at).format('YYYY-MM-DD') :
                                                        moment(rowData?.course_join_date).format('YYYY-MM-DD')}
                                                </span>
                                </>
                            },
                            {
                                title: 'Number', field: 'full_name', width: "20%", render: rowData => <>
                                    <span style={{cursor: 'pointer'}}>{rowData.phone_number}</span>
                                </>
                            },
                            {
                                title: 'Action', field: 'refer', render: rowData => <>
                                    {rowData.payment_status !== 'Paid' ?
                                        <button style={{cursor: 'pointer'}} onClick={() => {
                                            if (platform === 'W') {
                                                // toggleShareModal();
                                            } else {
                                                window.location.href = `tel:${rowData.phone_number}`; // Replace with the phone number you want to call
                                            }
                                        }} className={'all-btn'}>{platform === 'W' ? 'Poke' : 'Call'}</button>
                                        :
                                        '.....'
                                    }
                                </>
                            },
                        ]}
                        data={tableData}
                    />
                </div>
            </div>
        </Modal>
    )

}

export default ReferralModal