import React, {useState} from 'react';
import {Button, Modal} from "reactstrap";
import MaterialTable from 'material-table';
import avatar from '../../../assets/img/theme/avatarnew.png'
import Loading from "../../Loading/Loading";
import {useHistory} from "react-router-dom";
import moment from "moment";

const DownlineModal = ({
                           showModal,
                           toggleModal,
                           heading,
                           tableData,
                           platform,
                           toggleShareModal
                       }) => {
    const history = useHistory();
    let base = window.config.imgUrl;


    const showUserProfile = (id) => {
        history.push(`/profile/otherProfile/${id}`);
    }
    return (
        <Modal
            keyboard={false}
            backdrop={'static'}
            className="modal-dialog-centered modal-lg"
            isOpen={showModal}
            toggle={() => toggleModal()}
        >
            <div className="modal-header">
                <h2 style={{justifyContent: 'center'}} className="modal-title" id="modal-title-default">
                    {heading}
                </h2>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => toggleModal()}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className={'modal-body m-0 p-0 '}>
                {tableData?.children && tableData?.children.length > 0 ?
                    <>
                        <div className={'m-4'}>
                            <MaterialTable
                                options={{
                                    search: false,
                                    showTitle: false,
                                    toolbar: false,
                                    actionsColumnIndex: -1,
                                    padding: "dense"
                                }}


                                columns={[
                                    {
                                        title: 'Photo',
                                        field: 'imageUrl',
                                        render: rowData => <img
                                            src={rowData.profile_picture ? base.concat('/media/').concat(rowData.profile_picture) : avatar}
                                            style={{width: 40,height:40, borderRadius: '50%'}}/>
                                    },
                                    {
                                        title: 'Name', field: 'full_name',
                                        render: rowData =>
                                            <>
                                            <span style={{cursor: 'pointer'}}
                                                  onClick={() => showUserProfile(rowData.id)}>{rowData.full_name}
                                            </span>
                                            </>
                                    },
                                    {title: 'Phone', field: 'phone_number'},
                                    {
                                        title: 'Payment Status',
                                        field: 'payment_status',
                                        render: rowData => <>
                                            <div
                                                className={rowData?.payment_status === 'Paid' ? 'badge-success' : 'badge-danger'}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: 'center',
                                                    width: '60px',
                                                    padding: '2px',
                                                    borderRadius: '10px',
                                                    cursor: 'pointer'
                                                }}>
                                                <span style={{fontSize: '12px'}}>{rowData?.payment_status}</span>
                                            </div>
                                            <div style={{fontSize: '9px',marginTop:'5px'}}>
                                                {rowData?.course_join_date?.toUpperCase() === 'NONE' ?
                                                    <span>
                                                        Joined:{moment(rowData?.created_at).format('YYYY-MM-DD')}
                                                    </span> :
                                                    <span>
                                                        Paid:{moment(rowData?.course_join_date).format('YYYY-MM-DD')}
                                                    </span>
                                                }
                                            </div>
                                        </>
                                    },

                                    {
                                        title: 'Amount', field: 'refer',
                                        render: rowData => <>
                                            <span>{rowData.amount_earning || 0}</span>
                                        </>
                                    },
                                    {
                                        title: 'Action', field: 'refer',
                                        render: rowData => <>
                                            {rowData.payment_status !== 'Paid' ?
                                                <button style={{cursor: 'pointer'}} onClick={() => {
                                                    if (platform === 'W') {
                                                        // toggleShareModal();
                                                    } else {
                                                        window.location.href = `tel:${rowData.phone_number}`; // Replace with the phone number you want to call
                                                    }
                                                }} className={'all-btn'}>{platform === 'W' ? 'Poke' : 'Call'}</button>
                                                :
                                                '.....'
                                            }
                                        </>
                                    },
                                ]}
                                data={tableData.children}
                            />
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            padding: '20px',
                            fontWeight: 'bold'
                        }}>
                            Total:
                            Rs {heading === 'Direct' ? tableData.downline_1_earning || 0 : heading === 'Indirect' ? tableData.downline_2_earning || 0 : tableData.downline_3_earning || 0}
                        </div>
                    </>
                    : tableData?.children?.length === 0 ?
                        <div style={{
                            display: "flex",
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'center',
                            marginTop: '30px',
                            marginBottom: '30px'
                        }}>
                            <span style={{color: 'indigo'}}>No Data Found</span>
                        </div>
                        :
                        <>
                            <Loading/>
                        </>
                }
            </div>
        </Modal>
    )

}

export default DownlineModal