import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { Button, Container } from 'reactstrap';
import socketIO, { io } from 'socket.io-client';
import './Message.css';
import Loading from 'components/Loading/Loading';
import { ThreeDots } from 'react-loader-spinner';
import messageSound from '../../assets/sound/sound.mp3';
const Services = require('../../RemoteServices/RemoteServices.js');

const MessageUs = (props) => {
    const messageContainerRef = useRef(null);
    const messageInputRef = useRef(null);
    const [isUserScrolling, setIsUserScrolling] = useState(false);

    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [adminTyping, setAdminTyping] = useState(false);
    const [fullName, setFullName] = useState('');
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const pageSize = 10;
    const [socket, setSocket] = useState(props.socket ? props.socket : null);
    const [allMessagesLoaded, setAllMessagesLoaded] = useState(false); // Track if all messages are loaded
    const [nextUrl, setNextUrl] = useState('');
    const [chatConfig, setChatConfig] = useState('');

    const getChatConfig = async () => {
        await Services.getChatConfig()
            .then((response) => {
                setChatConfig(response?.[0]);
            })
            .catch((error) => {
                throw error;
            });
    };

    const getChatMessage = async () => {
        if (props.socket) {
            props.resetMessage();
            await Services.getChatMessage(JSON.parse(localStorage.getItem('user')).id)
                .then((response) => {
                    setMessages(response.results.reverse());
                    setNextUrl(response?.next);
                    let delivery;
                    response.results
                        .filter((item) => item.delivery_type === 'sent' || item.delivery_type === 'delivered')
                        .map((item) => {
                            console.log('asdf', item);
                            delivery = {
                                action: 'delivery_status',
                                id: item.id,
                                delivery_type: 'seen',
                                me_id: JSON.parse(localStorage.getItem('user'))?.id,
                                user_id: item.user,
                            };
                            return props?.socket?.send(JSON.stringify(delivery));
                        });
                })
                .catch((error) => {
                    throw error;
                });
        }
    };

    const scrollToBottom = () => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
    };

    const isURL = (message) => {
        const urlPattern = new RegExp(
            '^(https?:\\/\\/)' +
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' +
                '((\\d{1,3}\\.){3}\\d{1,3}))' +
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
                '(\\?[;&a-z\\d%_.~+=-]*)?' +
                '(\\#[-a-z\\d_]*)?$',
            'i'
        );
        return urlPattern.test(message);
    };

    const renderMessage = (message) => {
        const paragraphs = message.split('\n').filter(Boolean); // Filter out empty strings

        return paragraphs.map((paragraph, index) => {
            const words = paragraph.split(' ').map((word, wordIndex) => {
                if (isURL(word)) {
                    return (
                        <a
                            key={wordIndex}
                            style={{ color: 'blue', fontSize: 13 }}
                            href={word}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {word}
                        </a>
                    );
                } else {
                    return (
                        <span key={wordIndex} style={{ fontSize: 13 }}>
                            {word}{' '}
                        </span>
                    );
                }
            });

            // Check if it's the last paragraph to avoid unnecessary margin
            const isLastParagraph = index === paragraphs.length - 1;

            return (
                <div key={index} style={{ marginBottom: isLastParagraph ? 0 : '10px' }}>
                    {words}
                </div>
            );
        });
    };

    const updateDeliveryStatus = (incomingMessage) => {
        setMessages((prevMessages) => {
            // Create a new array with updated delivery_type
            const updatedMessages = prevMessages.map((msg) =>
                msg.id === incomingMessage.id ? { ...msg, delivery_type: incomingMessage.delivery_type } : msg
            );
            return updatedMessages;
        });
    };

    useEffect(() => {
        // Fetch initial messages
        getChatConfig();
        getChatMessage();
    }, []);

    useEffect(() => {
        setAdminTyping(props.adminTyping);
        setFullName(props.fullName);
        // scrollToBottom();
        // Scroll to the bottom whenever messages state changes
        if (!isUserScrolling) {
            scrollToBottom();
        }
    }, [props.messages, props.adminTyping]);

    useEffect(() => {
        const interval = setInterval(() => {
            // Trigger a re-render by updating the state
            setMessages((prevMessages) => [...prevMessages]);
        }, 60000); // Update every minute
        

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);

    let base = window.config.imgUrl;

    const handleScroll = () => {
        if (messageContainerRef.current.scrollTop === 0 && !loading && !allMessagesLoaded) {
            const prevHeight = messageContainerRef.current.scrollHeight;

            if (props.nextUrl) {
                props.getNext().then(() => {
                    // Adjust scroll position after loading more messages
                    messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight - prevHeight;
                });
            }
            setIsUserScrolling(true);
            setTimeout(() => {
                setIsUserScrolling(false);
            }, 20000);
        }
    };

    const scrollToPreviousPosition = (delay) => {
        setTimeout(() => {
            const prevHeight = messageContainerRef.current.scrollHeight;
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight - prevHeight;
        }, delay);
    };

    const handleInputChange = (e) => {
        const textarea = messageInputRef.current;
        if (e.target.value === '') {
            textarea.style.height = '53px'; // Reset height to initial value if the input is cleared
        } else {
            textarea.style.height = '53px'; // Reset height to auto to get the correct scrollHeight
            textarea.style.height = Math.min(textarea.scrollHeight, 200) + 'px'; // Set height to scrollHeight or 200px max
        }

        if (e.keyCode !== 13) {
            props.socket.send(
                JSON.stringify({
                    full_name: JSON.parse(localStorage.getItem('user')).full_name,
                    action: 'typing',
                    typing: true,
                    user_id: 50843,
                    me_id: JSON.parse(localStorage.getItem('user')).id,
                })
            );
            setTimeout(() => {
                props.socket.send(
                    JSON.stringify({
                        full_name: JSON.parse(localStorage.getItem('user')).full_name,
                        action: 'typing',
                        typing: false,
                        user_id: 50843,
                        me_id: JSON.parse(localStorage.getItem('user')).id,
                    })
                );
                setAdminTyping(false);
            }, 5000);
        }
        setMessage(e.target.value);
    };

    const sendMessage = async () => {
        if (message.trim() === '') return;
        const newMessage = {
            message: message,
            fromUser: true,
            user: JSON.parse(localStorage.getItem('user')).id,
            status: 'sending',
            sentTime: new Date().toLocaleTimeString(),
            seenTime: null,
        };
        const messages = {
            full_name: JSON.parse(localStorage.getItem('user')).full_name, // Replace with the sender's name
            icon: '',
            timestamp: new Date().toISOString(),
            state: 'sent',
            me_id: JSON.parse(localStorage.getItem('user'))?.id,
            to_user_id: 'admin',
            user_id: JSON.parse(localStorage.getItem('user'))?.id,
            user: JSON.parse(localStorage.getItem('user'))?.id,
            userImage: JSON.parse(localStorage.getItem('user'))?.profile_picture || null,
            message: message,
            action: 'message',
            phone_number: localStorage.getItem('user')?.phone_number,
        };
        props.socket.send(JSON.stringify(messages));
        setIsUserScrolling(false);
        setMessage('');
        const textarea = messageInputRef.current;
        textarea.style.height = '60px'; // Reset height to initial value
    };

    const formatSeenTime = (time) => {
        return moment(time, 'HH:mm:ss').fromNow();
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (e.shiftKey) {
                // Allow line break on Shift + Enter
                return;
            }
            e.preventDefault(); // Prevent default behavior of Enter key (new line)
            sendMessage(); // Call sendMessage function
        }
    };

    return (
        <>
            <Container className={'container'} style={{ minHeight: '682px' }}>
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                            <img
                                src={require('../../assets/img/theme/avatarnew.png')}
                                style={{
                                    width: 50,
                                    height: 50,
                                    borderRadius: 25,
                                }}
                                alt="User Avatar"
                            />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <p className='m-0 p-0'>English Hub</p>&nbsp;
                                    <i className='fa  fa-check-circle' size={16} />
                                </div>
                                <p style={{ fontSize: 10 }}>Online</p>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
                            <a style={{ color: 'indigo' }} href={chatConfig.audio_link} target='_blank'><i style={{ cursor: 'pointer' }} className='fa fa-phone' /></a>
                            <a style={{ color: 'indigo' }} href={chatConfig.video_link} target='_blank'> <i style={{ cursor: 'pointer' }} className='fa fa-video' /></a>
                        </div>
                    </div>
                </div>
                <div className="fixed-container" ref={messageContainerRef} onScroll={() => {
                    if (!props.loading) {
                        handleScroll();
                    }
                }}>
                    {props.loading &&
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <ThreeDots
                                height="50"
                                width="50"
                                color="#6C32A0"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />
                        </div>
                    }
                    <div className="message-container">
                        {props?.messages.map((item, index) => (
                            // <div key={index} className={`message ${item.fromUser ? 'user-message' : 'admin-message'}`}>

                            <div key={index} className={`message ${item?.to_user_id === 'admin' ? 'user-message' : 'admin-message'}`}>
                                {item.to_user_id !== 'admin' && (
                                    <img src={item.userImage || require('../../assets/img/theme/avatarnew.png')} alt="Avatar" className="avatar" />
                                )}
                                <div className={`message ${item?.to_user_id === 'admin' ? 'message-content' : 'message-content-admin'}`}>
                                    {renderMessage(item.message)}
                                    <div className="info-container">
                                        <p className="m-0 p-0 sent-time">{item.to_user_id !== 'admin' ? item.full_name !== null ? item.full_name + ' ' + moment(item.timestamp).fromNow() : 'English Hub' + ' ' + moment(item.timestamp).fromNow() : moment(item.timestamp).fromNow()}</p>&nbsp;
                                        {console.log('asdfasdf', item.to_user_id)}
                                        {item.to_user_id === 'user' || item.to_user_id === JSON.parse(localStorage.getItem('user')).id ?
                                            '' :
                                            <>
                                                {item.delivery_type === 'seen' ?
                                                    (<i style={{ fontSize: 12 }} className="fa fa-check-circle" />
                                                    ) : item.delivery_type === 'delivered' ? (
                                                        <i style={{ fontSize: 12 }} className="fa fa-check-double" />
                                                    ) : (
                                                        <i style={{ fontSize: 12 }} className="fa fa-check" />
                                                    )
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {adminTyping ? <p className="typing">{fullName} is typing...</p> : ''}

                </div>
                <br />
                {props.socket ?
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', gap: 2 }}>
                        <textarea
                            ref={messageInputRef}
                            placeholder="Type a message..."
                            value={message}
                            rows={1}
                            style={{ height: '53px', width: '90%', resize: 'none', overflow: 'hidden', borderRadius: 10 }}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                        />
                        <button className='btn all-btn' style={{ height: '50px', cursor: 'pointer' }} onClick={() => sendMessage()}>
                            <i className="fa fa-paper-plane" />&nbsp;Send
                        </button>
                    </div>
                    :
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <span>Connecting....</span>
                    </div>
                }
            </Container>
        </>
    );
};

export default MessageUs;