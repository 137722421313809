import React from 'react';
import {withSnackbar} from "notistack";
import {Button, FormGroup, Input, Label, Modal} from "reactstrap";
import Services from "../../RemoteServices/RemoteServices";
import MaterialTable from "material-table";
import avatar from "../../assets/img/theme/avatarnew.png";

class RewardModal extends React.Component {
    state = {
        createAccount: false,
        userDetails: this.props.userDetail,
        showFilter: false,
        withdrawMoney: false,
        noReward: true,
        showBank: false,
        showWallet: false,
        bankName: '',
        bankAccountNumber: '',
        bankAccountName: '',
        walletName: '',
        walletAccountName: '',
        walletNumber: '',
        walletList: '',
        addWallet: false,
        walletIndex: '',
        selectedWalletId: null,
        editWallet: false,
    }


    selectWalletForPaymentRequest = (item, index) => {
        this.setState(prevState => ({
            ...prevState,
            walletIndex: index,
            selectedWalletID: item.id,
        }))

    }

    requestPayment = async () => {
        let reqDto = {
            wallet: this.state.selectedWalletID
        }
        await Services.withdrawRequest(reqDto)
            .then((response) => {
                this.props.toggleModal("showReward")
                this.props.enqueueSnackbar(`Successfully Requested`, {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                });
            })
            .catch((error) => {
                this.props.toggleModal("showReward")
                this.props.enqueueSnackbar(`Please Contact Administrator`, {
                    variant: "danger",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                });
            });
    }


    handleSubmit = async (event, values) => {
        event.preventDefault();
        await this.setState({createAccount: true});
        await Services.updateStaff(this.state.userDetails.id, values)
            .then((response) => {
                localStorage.setItem("user", JSON.stringify(response))
                this.props.toggleModal("updateProfile")
            })
            .catch((error) => {
                this.setState({loggingIn: false});
            });
    }
    handleInvalidSubmit = (event, errors) => {
        this.props.enqueueSnackbar(`Please enter ${errors.join(" and ")}`, {
            variant: "error",
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
            },
        });
    };

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState(prevState => ({
            ...prevState,
            [name]: value
        }));

    }


    getWallet = async () => {
        await Services.getWalletDetails().then((response) => {
            this.setState(prevState => ({
                ...prevState,
                walletList: response,
            }))
        });
        if (this.state.walletList.length <= 3) {
            this.setState(prevState => ({
                ...prevState,
                addWallet: false
            }))
        } else {
            this.setState(prevState => ({
                ...prevState,
                addWallet: true
            }))
        }
    }
    handleUpdate = async () => {
        let walletData = {
            type: this.state.showBank ? "Bank" : this.state.walletName,
            bank_name: this.state.showBank ? this.state.bankName : this.state.walletName,
            full_name: this.state.showBank ? this.state.bankAccountName : this.state.walletAccountName,
            number: this.state.showBank ? this.state.bankAccountNumber : this.state.walletNumber,
        };
        Services.setWalletDetails(walletData).then((response) => {
            this.getWallet();
            this.setState({
                showWallet: false,
                bankName: '',
                bankAccountNumber: '',
                bankAccountName: '',
                walletName: '',
                walletAccountName: '',
                walletNumber: '',
                walletList: '',
                addWallet: false,
                walletIndex: '',
                selectedWalletId: null
            })
            // this.props.onSuccess();
        });
        // this.props.onSuccess()
    }

    async componentDidMount() {
        await this.getWallet();
        await this.setState(prevState => ({
            ...prevState,
            updateProfile: this.props.updateProfile,
            showWallet: false,
        }))
        const userDetail = this.props.userDetail;
        this.setState({
            userDetails: userDetail
        })

    }

    toggleModal = async (state) => {
        await this.setState({
            showFilter: false,
            withdrawMoney: false,
            noReward: true,
            showBank: false,
            showWallet: false,
        })
        await this.setState({
            [state]: !this.state[state],
        });
    };

    deletePaymentDetail = async (item) => {
        await Services.deleteWalletDetail(item.id).then((response) => {
            this.getWallet();
            this.props.enqueueSnackbar(`Wallet Successfully Deleted`, {
                variant: "success",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
            });
        }).catch((error) => {
            this.props.enqueueSnackbar(`Wallet Delete Failed`, {
                variant: "danger",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
            });

        });
    }

    render() {
        const {userDetails} = this.state;
        return (
            <>
                <Modal
                    keyboard={false}
                    backdrop={'static'}
                    className="modal-dialog-centered modal-md"
                    isOpen={this.props.showReward}
                    toggle={() => this.props.toggleModal("showReward")}
                >
                    <div className="modal-header">
                        <h2 className="modal-title" id="modal-title-default">
                            Rewards
                        </h2>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.props.toggleModal('showReward')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className={'modal-body mt--4'}>
                        {userDetails?.paid_referrals !== 0 ?
                            <>
                                <div style={{
                                    display: 'flex',
                                    width: '100%',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <h2>
                                        Congratulations!
                                    </h2>
                                    <h5>
                                        Congratulations! You have earned a total of
                                        Rs. {Number(userDetails?.delivered_amount + userDetails?.user?.remaining_balance)}/-,
                                        Your remaining balance
                                        is Rs.
                                        {userDetails?.user?.remaining_balance}/-
                                    </h5>
                                </div>
                                <div className={'mt-1'}
                                     style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <h3>Total Referrals: {userDetails?.user?.referred_count}</h3>
                                    <h3>Paid Referrals: {userDetails?.paid_referrals}</h3>
                                </div>
                                <h3 className={'mt-1'}>Total Amount Earned:
                                    Rs. {Number(userDetails?.delivered_amount + userDetails?.user?.remaining_balance)}/-</h3>
                                <h3 className={'mt-1'}>Amount Remaining:
                                    Rs. {userDetails?.user?.remaining_balance}/-</h3>
                                <div className={'mt-3'}
                                     style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                    <Button onClick={() => this.props.toggleModal('showWithdrawMoney')}
                                            className={'btn btn-info'}>Withdraw Money</Button>
                                </div>
                            </>
                            :
                            <>
                                <div style={{
                                    display: 'flex',
                                    width: '100%',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <h2>
                                        Hello User
                                    </h2>
                                    <h5>
                                        You have not earn any reward yet. Earn rewards by referring your friend or by
                                        Watching a video.
                                    </h5>
                                </div>
                                <div style={{
                                    width: '100%',
                                    marginTop: '30px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-evenly'
                                }}>
                                    <Button className={'btn btn-success'}>Refer</Button>
                                    <Button className={'btn btn-success'}>Watch Video</Button>
                                </div>
                            </>

                        }


                    </div>
                </Modal>
                <Modal
                    keyboard={false}
                    backdrop={'static'}
                    className="modal-dialog-centered modal-md"
                    isOpen={this.props.showWithdrawMoney}
                    toggle={() => this.props.toggleModal("showWithdrawMoney")}
                >
                    <div className="modal-header">
                        <h2 className="modal-title" id="modal-title-default">
                            {userDetails.wallet !== null ? 'Choose Bank Or Wallet' : 'Update Your Account Details'}
                        </h2>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.props.toggleModal('showWithdrawMoney')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className={'modal-body'}>
                        {this.state.walletList === null ?
                            <div style={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <h4 className={'mt--4'}>Add Bank Or Wallet</h4>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-evenly'
                                }}>
                                    <Button onClick={() => this.toggleModal('showBank')} style={{
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                        backgroundColor: 'purple',
                                        color: 'white'
                                    }} className={'btn'}>Bank</Button>
                                    <Button onClick={() => this.toggleModal('showWallet')} style={{
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                        backgroundColor: 'purple',
                                        color: 'white'
                                    }} className={'btn'}>Wallet</Button>
                                </div>
                                {this.state.showBank && (
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                        justifyContent: 'center'
                                    }}>
                                        <div style={{width: '100%', flexDirection: 'column', justifyContent: 'center'}}>
                                            <FormGroup className={'mt-4'}>
                                                <Input type="select" name="bankName" id="exampleSelect"
                                                       onChange={this.handleInputChange}>
                                                    <option>Select Bank</option>
                                                    <option value={'Nabil'}>Nabil</option>
                                                    <option value={'Global_Ime'}>Global IME</option>
                                                    <option value={'ADBL'}>ADBL</option>
                                                </Input>
                                                <Input className={'mt-2'} placeholder={'Account Name'}
                                                       type={'text'} name={'bankAccountName'}
                                                       value={this.state.bankAccountName}
                                                       onChange={this.handleInputChange}></Input>
                                                <Input className={'mt-2'} placeholder={'Account Number'}
                                                       type={'text'}
                                                       name={'bankAccountNumber'} value={this.state.bankAccountNumber}
                                                       onChange={this.handleInputChange}
                                                ></Input>
                                                <Input className={'mt-2'} placeholder={'Branch'} type={'text'}></Input>
                                            </FormGroup>
                                            <div style={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center'
                                            }}>
                                                <Button onClick={async () => {
                                                    await this.handleUpdate();
                                                    this.toggleModal('showWallet')
                                                }} style={{
                                                    paddingLeft: '20px',
                                                    paddingRight: '20px',
                                                    backgroundColor: 'purple',
                                                    color: 'white'
                                                }} className={'btn'}>Update</Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.state.showWallet && (
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                        justifyContent: 'center'
                                    }}>
                                        <div style={{width: '80%', flexDirection: 'column', justifyContent: 'center'}}>
                                            <FormGroup className={'mt-4'}>
                                                <Input type="select" name="walletName" value={this.state.walletName}
                                                       onChange={this.handleInputChange} id="exampleSelect">
                                                    <option>Select Wallet</option>
                                                    <option value={'Esewa'}>Esewa</option>
                                                    <option value={'Khalti'}>Khalti</option>
                                                    <option value={'ImePay'}>ImePay</option>
                                                </Input>
                                                <Input name={'walletAccountName'} value={this.state.walletAccountName}
                                                       onChange={this.handleInputChange} className={'mt-2'}
                                                       placeholder={'Name'} type={'text'}></Input>
                                                <Input name={'walletNumber'} value={this.state.walletNumber}
                                                       onChange={this.handleInputChange} className={'mt-2'}
                                                       placeholder={'ID'} type={'text'}></Input>
                                            </FormGroup>
                                            <div style={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center'
                                            }}>
                                                <Button onClick={async () => {
                                                    await this.handleUpdate();
                                                }}
                                                        style={{
                                                            paddingLeft: '20px',
                                                            paddingRight: '20px',
                                                            backgroundColor: 'purple',
                                                            color: 'white'
                                                        }} className={'btn'}>Update</Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div> :
                            <div className={'mt--3'}>
                                <>
                                    {!this.state.addWallet && this.state.walletList.length < 3 &&
                                        <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                                            <Button onClick={() => this.toggleModal('addWallet')} style={{
                                                paddingLeft: '20px',
                                                paddingRight: '20px',
                                                backgroundColor: 'purple',
                                                color: 'white',
                                                width: '100%'
                                            }} className={'btn'}>Add Another Wallet</Button>
                                        </div>
                                    }

                                    {this.state.addWallet &&
                                        <div style={{
                                            display: 'flex',
                                            width: '100%',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-evenly'
                                            }}>
                                                <Button onClick={() => this.toggleModal('showBank')} style={{
                                                    paddingLeft: '20px',
                                                    paddingRight: '20px',
                                                    backgroundColor: 'purple',
                                                    color: 'white'
                                                }} className={'btn'}>Bank</Button>
                                                <Button onClick={() => this.toggleModal('showWallet')} style={{
                                                    paddingLeft: '20px',
                                                    paddingRight: '20px',
                                                    backgroundColor: 'purple',
                                                    color: 'white'
                                                }} className={'btn'}>Wallet</Button>
                                            </div>
                                            {this.state.showBank && (
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    width: '100%',
                                                    justifyContent: 'center'
                                                }}>
                                                    <div style={{
                                                        width: '100%',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <FormGroup className={'mt-4'}>
                                                            <Input type="select" name="bankName" id="exampleSelect"
                                                                   onChange={this.handleInputChange}>
                                                                <option>Select Bank</option>
                                                                <option value={'Nabil'}>Nabil</option>
                                                                <option value={'Global_Ime'}>Global IME</option>
                                                                <option value={'ADBL'}>ADBL</option>
                                                            </Input>
                                                            <Input className={'mt-2'} placeholder={'Account Name'}
                                                                   type={'text'} name={'bankAccountName'}
                                                                   value={this.state.bankAccountName}
                                                                   onChange={this.handleInputChange}></Input>
                                                            <Input className={'mt-2'} placeholder={'Account Number'}
                                                                   type={'text'}
                                                                   name={'bankAccountNumber'}
                                                                   value={this.state.bankAccountNumber}
                                                                   onChange={this.handleInputChange}
                                                            ></Input>
                                                            <Input className={'mt-2'} placeholder={'Branch'}
                                                                   type={'text'}></Input>
                                                        </FormGroup>
                                                        <div style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'center'
                                                        }}>
                                                            <Button onClick={async () => {
                                                                await this.handleUpdate();
                                                                this.toggleModal('showWallet')
                                                            }} style={{
                                                                paddingLeft: '20px',
                                                                paddingRight: '20px',
                                                                backgroundColor: 'purple',
                                                                color: 'white'
                                                            }} className={'btn'}>Add</Button>
                                                            <Button onClick={async () => {
                                                                this.toggleModal('addWallet')
                                                            }} style={{
                                                                paddingLeft: '20px',
                                                                paddingRight: '20px',
                                                                backgroundColor: 'red',
                                                                color: 'white'
                                                            }} className={'btn'}>Close</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {this.state.showWallet && (
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    width: '100%',
                                                    justifyContent: 'center'
                                                }}>
                                                    <div style={{
                                                        width: '80%',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <FormGroup className={'mt-4'}>
                                                            <Input type="select" name="walletName"
                                                                   value={this.state.walletName}
                                                                   onChange={this.handleInputChange} id="exampleSelect">
                                                                <option>Select Wallet</option>
                                                                <option value={'Esewa'}>Esewa</option>
                                                                <option value={'Khalti'}>Khalti</option>
                                                                <option value={'ImePay'}>ImePay</option>
                                                            </Input>
                                                            <Input name={'walletAccountName'}
                                                                   value={this.state.walletAccountName}
                                                                   onChange={this.handleInputChange} className={'mt-2'}
                                                                   placeholder={'Name'} type={'text'}></Input>
                                                            <Input name={'walletNumber'} value={this.state.walletNumber}
                                                                   onChange={this.handleInputChange} className={'mt-2'}
                                                                   placeholder={'ID'} type={'text'}></Input>
                                                        </FormGroup>
                                                        <div style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'center'
                                                        }}>
                                                            <Button onClick={async () => {
                                                                await this.handleUpdate();
                                                            }}
                                                                    style={{
                                                                        paddingLeft: '20px',
                                                                        paddingRight: '20px',
                                                                        backgroundColor: 'purple',
                                                                        color: 'white'
                                                                    }} className={'btn'}>Add</Button>

                                                            <Button onClick={async () => {
                                                                this.toggleModal('addWallet')
                                                            }} style={{
                                                                paddingLeft: '20px',
                                                                paddingRight: '20px',
                                                                backgroundColor: 'red',
                                                                color: 'white'
                                                            }} className={'btn'}>Close</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    }


                                    {this.state.walletList && this.state.walletList.map((item, index) => {
                                        return (
                                            <FormGroup className={'m-0 p-0'}>
                                                <Button
                                                    style={{
                                                        display: 'flex', flexDirection: 'row', width: '100%',
                                                        paddingLeft: '20px',
                                                        paddingRight: '20px',
                                                        backgroundColor: 'purple',
                                                        color: 'white',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        marginTop:'10px'
                                                    }}
                                                    onClick={() => this.selectWalletForPaymentRequest(item, index)}
                                                >
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        marginLeft: '10px',
                                                        alignItems:'center',
                                                    }}>
                                                        <Input
                                                            style={{width:'20px',height:'20px'}}
                                                            name="wallet"
                                                            type="radio"
                                                            checked={this.state.walletIndex === index}
                                                        />
                                                        <div style={{marginLeft:'20px',display: 'flex',flexDirection:'column',alignItems:'flex-start'}}>
                                                            <span>{item.bank_name}</span>
                                                            <span>{item.full_name} < /span>
                                                            <span>{item.number}</span>
                                                        </div>
                                                    </div>
                                                    <div className={'float-right'}>
                                                        <i style={{fontSize:22}} className={'fa fa-trash'}
                                                           onClick={() => this.deletePaymentDetail(item)}/>
                                                    </div>
                                                </Button>
                                            </FormGroup>
                                        )
                                    })}
                                    <div className={'mt-3'}
                                         style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                        <Button onClick={() => this.requestPayment()}
                                                style={{backgroundColor: 'purple', color: 'white'}}
                                                className={'btn btn-info'}>Request</Button>
                                    </div>
                                </>
                            </div>
                        }
                    </div>
                </Modal>

            </>
        )

    }
}

export default withSnackbar(RewardModal)
