const axios = require("axios");

const prabhupay = async (params) => {
  try {
    const response = await axios.post(
      "https://stagesys.prabhupay.com/api/EPayment/Initiate",
      params
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export { prabhupay };
