import React, {useEffect, useState} from 'react';
import {Button, Modal} from "reactstrap";
import MaterialTable from 'material-table';
import avatar from '../../../assets/img/theme/avatarnew.png'
import Services from "../../../RemoteServices/RemoteServices";
import {useHistory} from "react-router-dom";
import moment from "moment";

const TotalModal = ({
                        showModal,
                        toggleModal,
                        tableData,
                        heirarchyDetail
                    }) => {

    const [downline1, setDownline1] = useState([])
    const [downline2, setDownline2] = useState([]);
    const [downline3, setDownline3] = useState([])

    let base = window.config.imgUrl
    const history = useHistory();


    const showUserProfile = (id) => {
        history.push(`/profile/otherProfile/${id}`);
    }

    const getDownline1 = (heading, count) => {
        const userId = JSON.parse(localStorage.getItem('user'))?.id
        Services.postHierarchy(userId, {downline_count: 1}).then((response) => {
            setDownline1(response.data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const getDownline2 = (heading, count) => {
        const userId = JSON.parse(localStorage.getItem('user'))?.id
        Services.postHierarchy(userId, {downline_count: 2}).then((response) => {
            setDownline2(response.data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const getDownline3 = (heading, count) => {
        const userId = JSON.parse(localStorage.getItem('user'))?.id
        Services.postHierarchy(userId, {downline_count: 3}).then((response) => {
            setDownline3(response.data)
        }).catch((error) => {
            console.log(error)
        })
    }
    useEffect(() => {
        getDownline1();
        getDownline2();
        getDownline3();
    }, []);

    return (
        <Modal
            keyboard={false}
            backdrop={'static'}
            className="modal-dialog-centered modal-lg"
            isOpen={showModal}
            toggle={() => toggleModal()}
        >
            <div className="modal-header">
                <h2 style={{justifyContent: 'center'}} className="modal-title" id="modal-title-default">
                    Total Reward
                </h2>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => toggleModal()}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className={'modal-body m-0 p-0 '} style={{minHeight: '30rem'}}>
                <div className={'mt-2 ml-4 mb-2 mr-4'}>
                    <h2>Total Amount:
                        Rs {heirarchyDetail.downline_1_earning + heirarchyDetail.downline_2_earning + heirarchyDetail.downline_3_earning || 0}</h2>
                </div>
                {downline1?.children?.filter(child => child.payment_status === 'Paid')?.length > 0 &&
                    <div className={'m-4'}>
                        <h4>Downline 1 (Rs.{heirarchyDetail.downline_1_earning || 0})</h4>
                        <MaterialTable
                            options={{
                                search: false,
                                showTitle: false,
                                toolbar: false,
                                actionsColumnIndex: -1,
                                padding: "dense"
                            }}
                            columns={[
                                {
                                    title: 'Photo',
                                    field: 'imageUrl',
                                    width: '8%',
                                    render: rowData => <img
                                        src={rowData.profile_picture ? base.concat('/media/').concat(rowData.profile_picture) : avatar}
                                        style={{width: 40,height:40, borderRadius: '50%'}}/>
                                },
                                {
                                    title: 'Name', field: 'full_name', width: "20%", render: rowData => <>
                                    <span onClick={() => showUserProfile(rowData.id)}
                                          style={{cursor: 'pointer'}}>{rowData.full_name}</span>
                                    </>
                                },
                                {
                                    title: 'Status', field: 'payment_status', width: "20%", render: rowData => <>
                                        <div
                                            className={rowData?.payment_status === 'Paid' ? 'badge-success' : 'badge-danger'}
                                            style={{
                                                display: "flex",
                                                justifyContent: 'center',
                                                width: '60px', padding: '2px', borderRadius: '10px', cursor: 'pointer'
                                            }}>
                                            <span style={{fontSize: '12px'}}>{rowData?.payment_status}</span>
                                        </div>
                                    </>
                                },
                                {
                                    title: 'Joined',
                                    render: rowData => <>
                                                <span >
                                                    {rowData?.course_join_date?.toUpperCase() === 'NONE' ?
                                                        moment(rowData?.created_at).format('YYYY-MM-DD') :
                                                        moment(rowData?.course_join_date).format('YYYY-MM-DD')}
                                                </span>
                                    </>
                                },
                                {
                                    title: 'Amount', field: 'refer', render: rowData => <>
                                        <span>{rowData.amount_earning || 0}</span>
                                    </>
                                },
                            ]}
                            data={downline1?.children?.filter(child => child.payment_status === 'Paid')}
                        />
                    </div>
                }
                {downline2?.children?.filter(child => child.payment_status === 'Paid')?.length > 0 &&
                    <div className={'m-4'}>
                        <h4>Downline 2 (Rs. {heirarchyDetail.downline_2_earning || 0})</h4>
                        <MaterialTable
                            options={{
                                search: false,
                                showTitle: false,
                                toolbar: false,
                                actionsColumnIndex: -1,
                                padding: "dense"
                            }}
                            columns={[
                                {
                                    title: 'Photo',
                                    field: 'imageUrl',
                                    width: '8%',
                                    render: rowData => <img
                                        src={rowData.profile_picture ? base.concat('/media/').concat(rowData.profile_picture) : avatar}
                                        style={{width: 40, height: 40, borderRadius: '50%'}}/>
                                },
                                {
                                    title: 'Name', field: 'full_name', width: "20%", render: rowData => <>
                                    <span onClick={() => showUserProfile(rowData.id)}
                                          style={{cursor: 'pointer'}}>{rowData.full_name}</span>
                                    </>
                                },
                                {
                                    title: 'Status', field: 'payment_status', width: "20%", render: rowData => <>
                                        <div
                                            className={rowData?.payment_status === 'Paid' ? 'badge-success' : 'badge-danger'}
                                            style={{
                                                display: "flex",
                                                justifyContent: 'center',
                                                width: '60px', padding: '2px', borderRadius: '10px', cursor: 'pointer'
                                            }}>
                                            <span style={{fontSize: '12px'}}>{rowData?.payment_status}</span>
                                        </div>
                                    </>
                                },
                                {
                                    title: 'Joined',
                                    render: rowData => <>
                                                <span >
                                                    {rowData?.course_join_date?.toUpperCase() === 'NONE' ?
                                                        moment(rowData?.created_at).format('YYYY-MM-DD') :
                                                        moment(rowData?.course_join_date).format('YYYY-MM-DD')}
                                                </span>
                                    </>
                                },
                                {
                                    title: 'Amount', field: 'refer', render: rowData => <>
                                        <span>{rowData.amount_earning || 0}</span>
                                    </>
                                },
                            ]}
                            data={downline2?.children?.filter(child => child.payment_status === 'Paid')}
                        />
                    </div>
                }
                {downline3?.children?.filter(child => child.payment_status === 'Paid')?.length > 0 &&
                    <div className={'m-4'}>
                        <h4>Downline 3 (Rs. {heirarchyDetail.downline_3_earning || 0})</h4>
                        <MaterialTable
                            options={{
                                search: false,
                                showTitle: false,
                                toolbar: false,
                                actionsColumnIndex: -1,
                                padding: "dense"
                            }}
                            columns={[
                                {
                                    title: 'Photo',
                                    field: 'imageUrl',
                                    width: '8%',
                                    render: rowData => <img
                                        src={rowData.profile_picture ? base.concat('/media/').concat(rowData.profile_picture) : avatar}
                                        style={{width: 40,height:40, borderRadius: '50%'}}/>
                                },
                                {
                                    title: 'Name', field: 'full_name', width: "20%", render: rowData => <>
                                    <span onClick={() => showUserProfile(rowData.id)}
                                          style={{cursor: 'pointer'}}>{rowData.full_name}</span>
                                    </>
                                },
                                {
                                    title: 'Status', field: 'payment_status', width: "20%", render: rowData => <>
                                        <div
                                            className={rowData?.payment_status === 'Paid' ? 'badge-success' : 'badge-danger'}
                                            style={{
                                                display: "flex",
                                                justifyContent: 'center',
                                                width: '60px', padding: '2px', borderRadius: '10px', cursor: 'pointer'
                                            }}>
                                            <span style={{fontSize: '12px'}}>{rowData?.payment_status}</span>
                                        </div>
                                    </>
                                },
                                {
                                    title: 'Joined',
                                    render: rowData => <>
                                                <span>
                                                    {rowData?.course_join_date?.toUpperCase() === 'NONE' ?
                                                        moment(rowData?.created_at).format('YYYY-MM-DD') :
                                                        moment(rowData?.course_join_date).format('YYYY-MM-DD')}
                                                </span>
                                    </>
                                },
                                {
                                    title: 'Amount', field: 'refer', render: rowData => <>
                                        <span>{rowData.amount_earning || 0}</span>
                                    </>
                                },
                            ]}
                            data={downline3?.children?.filter(child => child.payment_status === 'Paid')}
                        />
                    </div>
                }
            </div>
        </Modal>
    )

}

export default TotalModal